* {
  --primary-dark-color: #023D25;
  --primary-main-color: #00A15F;
  --primary-light-color: #edfffd;
  --secondary-main-color: #393A4A;
  --secondary-light-text-color: #A0A3BD;
  --xs-breakpoint: 0px;
  --sm-breakpoint: 600px;
  --md-breakpoint: 900px;
  --lg-breakpoint: 1200px;
  --xl-breakpoint: 1536px;
  box-sizing: border-box;
  /* color: #7fb913; */
}

table th,
table td {
  font-size: 14px;
}

table td {
  font-size: 14px;
  font-weight: 400;
}

table tr.clickable-row:hover{
  cursor: pointer;
  background-color: #F4F8FA;
}

.pin-code-input {
  border: 1px solid #c8cbd0;
  border-radius: 8px;
}

.pin-code-input:focus {
  border-width: 2px;
  border-color: var(--primary-main-color);
}

.pin-code-input:not(:placeholder-shown) {
  border-width: 1px;
  border-color: var(--primary-main-color);
}

.notice {
  width: 100%;
  background-color: #edfffd;
  margin-top: 24px;
  border-radius: 8px;
  padding: 15px 0;
}

.loader-indefinite {
  width: 100%;
}

.loader-indefinite > * {
  width: 15%;
  min-width: 25px;
  animation: loading-indefinite 1.5s ease-in-out 0s infinite alternate both;
}

.firstcontainericon {
  margin-left: 20.5px;
  margin-right: 15px;
  margin-top: 4px;
}

.firstcontainerfirstbox {
  display: flex;
  flex-direction: row;
}

.firstcontainersecondbox {
  margin-right: 12px;
}

.firstcontainerfirstinsidebox {
  display: flex;
  flex-direction: column;
}

.firstcontainerfirsttext {
  font-size: 16px;
  font-weight: 700;
  color: #31b5a7;
}

.firstcontainersecondtext {
  font-size: 14px;
  font-weight: 400;
  color: #717783;
}

.input-error,
.input-error .MuiInputBase-root {
  border: 1px solid #ff0000;
  /* transition: all 0.1s ease; */
  /* animation: shake 0.3s ease-in-out 0s 5 alternate backwards; */
}

.error {
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  color: #ff0000;
  margin-top: 3px;
}

.MuiStepLabel-label {
  margin-top: 8px;
}

#main-drawer [class*="Mui-selected"],
#main-drawer [class*="Mui-selected"] * {
  font-weight: 600;
  color: var(--primary-dark-theme) !important;
  fill: var(--primary-dark-theme);
}

#main-drawer [class*="Mui-selected"] {
  background-color: white;
}

#main-drawer [class*="MuiListItemIcon-root"] {
  min-width: 40px;
}

#drawer-logo {
  width: 149px;
  /* width: auto; */
  /* height: 35px; */
  height: auto;
  box-sizing: border-box;
  object-fit: contain;
  /* border: 1px solid red; */
}

.drawer-icons {
  font-size: 22px;
  color: #ffffff;
  color: var(--secondary-light-text-color);
  fill:  var(--secondary-light-text-color);
}

.list-item-text [class*="MuiListItemText-"] {
  font-size: 14px !important;
  font-weight: 600;
  color: #fff;
}

#main-page-content {
  height: calc(100vh - 66px);
  min-height: 500px;
  padding: 8px 24px;
  width: 100%;
  overflow: auto;
  /* max-width: calc(100vw - 305px); */
}

/* #main-page-content h1,
#main-page-content h2,
#main-page-content h3 {
  font-weight: bold;
  margin-top: 1em;
  margin-bottom: 1em;
} */

#main-page-content h2.with-spinner {
  display: flex;
  align-items: center;
}

#main-page-content h2.with-spinner > *:first-child {
  display: flex;
  align-items: center;
  margin-right: 1em;
}

#main-page-content > *:not([class*="notice"]):first-child {
  padding: 0;
}

#main-page-content #quick-nav {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 6px;
}

#main-page-content #quick-nav * {
  text-decoration: none;
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 700;
}

#view-page-content {
  background-color: #f4f8fa;
  display: flex;
  flex: 1;
  flex-direction: column;
  /* background-image: url(../src/assets/View-Area-BG.svg); */
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
}

#view-page-content .navbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-self: center;
  width: 90%;
  margin-top: 25px;
  margin-bottom: 20px;
}

.loan-calculator {
  display: flex;
  flex-direction: column;
  border: 1px solid #e7e7ed;
  width: 100%;
  /* max-width: 500px; */
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 0px;
}

.loan-calculator .title {
  margin: 20px 0 0 !important;
  font-size: 24px;
  font-weight: 700;
  color: #393a4a;
}

.loan-calculator label {
  text-align: left;
  align-self: flex-start;
  font-size: 12px;
  line-height: 18px;
  font-weight: 700;
  color: #6b778c;
  margin-top: 15px;
}

.loancalculatorpricediv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 0 5px;
}

.loancalculatorprice {
  font-size: 10px;
  font-weight: 700;
  color: #393a4a;
}

.loansubmitbutton {
  margin-top: 16px;
  margin-bottom: 12px;
}

.no-table-data {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 600;
  border-top: 1px solid #e8eaed;
  /* background-color: rgb(224, 224, 224); */
  padding: 40px;
  min-height: 200px;
  width: 100%;
}

.pane h3 {
  margin: 20px 0 0;
  font-size: 24px;
  font-weight: 700;
  color: #393a4a;
}

.current-loan-pane {
  background-color: #F4F8FA;
  margin-top: 24px;
  border-radius: 8px;
  padding-bottom: 18px;
}

.current-loan-pane .card {
  min-width: 160px;
  min-height: 135px;
  background-color: #EBFFF7;
  flex-direction: column;
  /* padding-left: 15px; */
  border-radius: 5px;
  /* margin: 0 5px; */
}

.current-loan-pane .divider {
  width: 1px;
  height: 84px;
  background-color: #c8cbd0;
}

.card .card-text.light {
  font-size: 14px;
  font-weight: 400;
  color: #717783;
  /* margin-top: 16px; */
  /* margin-bottom: 8px; */
}

.card .card-text.bold {
  font-weight: 18px;
  font-weight: 800;
  color: #11635b;
  margin-top: 8px;
}

.document-card,
.vendor-card {
  transition: box-shadow 0.3s ease-out;
  cursor: pointer;
}

.document-card:hover,
.vendor-card:hover {
  box-shadow: 0px 6px 20px rgba(108, 117, 125, 0.15);
}

.uploaddiv {
  background-color: #ffffff;
  border: 1px dashed #9CA1AA;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 4px;
  margin-bottom: 4px;
  width: 100%;
  min-height: 175px;
  border-radius: 5px;
}

.uploadimage {
  margin-top: 28px;
}

.uploadinstructdiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 16px;
}

.uploadinstruct1 {
  font-size: 16px;
  font-weight: 700;
}

.uploadinstruct2 {
  font-size: 14px;
  font-weight: 400;
  color: #9ca1aa;
}

.uploadinstruct3 {
  margin-top: 5px;
  font-size: 12px;
  font-weight: 400;
  color: #9ca1aa;
}

@media only screen and (max-width: 450px) {
}

.uploaddiv:hover{
  background-color: #f7f7f9;
  cursor: pointer;
}

.uploaddiv:focus{
  border:1px solid black;
}

@media screen and (max-width: 900px) {
  .hide-on-mobile {
    display: none !important;
  }
}

/* vendor page css */
.vendor-component {
  width: 23.5vw;
  height: 240px;
  border-radius: 5px;
  border: 1px solid #e7e7ed;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}
