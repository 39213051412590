#auth-screen {
  background-color: var(--primary-dark-color);
  background-image: url(../assets/background.svg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
  height: 100vh;
  min-height: 600px;
  overflow-y: auto;
}

#auth-screen > * > *{
  max-width: 552px;
}

#auth-screen .form_wrapper {
  background-color: white;
  /* padding: 2rem; */
  width: 100%;
  border-radius: 8px;
}

#auth-screen .form_wrapper > * {
  width: 100%;
}

#auth-screen .form_wrapper .form-head {
  box-shadow: inset 0px -1px 0px #F3F3F3;
  font-size: 16px;
  font-weight: 700;
  color: #393A4A;
}

#auth-screen .form-input-wrapper label{
  color: #717783;
  font-size: 12px;
  font-weight: 700;
  margin-bottom: 3px;
}

#auth-screen .form-input-wrapper input{
  outline: none;
}

#register-form .form-body {
  height: auto;
  transition: height 0.3s ease;
  animation: fade 0.9s ease-in 0.3s 1 normal backwards;
}

@media screen and (max-width: 600px) {
  #auth-screen {
    min-height: 100vh;
    height: auto;
  }
}

@media screen and (orientation: landscape) and (max-width: 600px) {
  #auth-screen {
    min-height: 600px;
  }
}

@media screen and (orientation: portrait) and (max-height: 700px) {
  #auth-screen {
    min-height: 600px;
  }
}

@-webkit-keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}